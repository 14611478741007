import { useMemo } from 'react';
import useQueryParams from './useQueryParams';

type QueryParams = {
  [key: string]: string | undefined;
};

export default function useQueryParamsAsSingleValues(search: string): QueryParams {
  const params = useQueryParams(search);

  return useMemo<QueryParams>(
    () =>
      Object.keys(params).reduce<QueryParams>((result, key) => {
        const value = params[key];
        const singleValue = Array.isArray(value) ? value[0] : value;
        result[key] = singleValue != null ? singleValue : undefined;

        return result;
      }, {}),
    [params]
  );
}
